/**
 * Barbara Bertolini Borlabs Cookie 1.0
 *
 * Copyright 2020 Wolf-Dieter Grabner, flowlabs.studio
 * https://www.flowlabs.studio
 *
 */

 #borlabsCookieBox {
   letter-spacing: 0.04em;
 }

#BorlabsCookieBox ._brlbs-block-content {
  z-index: 999;
}

 #BorlabsCookieBox ._brlbs-block-content._brlbs-bg-dark {
   background: rgba(0, 0, 0, 0.42);
 }

 #BorlabsCookieBox h3 {
   margin-bottom: 14px;
 }

 @media only screen and (min-width: 768px ) {
   #BorlabsCookieBox .bottom-right ._brlbs-box {
     margin-right: 30px;
     margin-bottom: 30px;
   }
 }

 #BorlabsCookieBox ._brlbs-box {
   box-shadow: none;
 }

 #BorlabsCookieBox ._brlbs-box-wrap {
   max-width: 460px;
 }

 #BorlabsCookieBox ._brlbs-box {
   padding: 20px 0;
 }

 #BorlabsCookieBox ._brlbs-box .container {
   padding: 0 20px;
 }

 #BorlabsCookieBox ._brlbs-btn {
   font-size: 16px;
   margin-top: 1rem;
 }

 #BorlabsCookieBox ._brlbs-manage {
   font-size: 16px;
   padding: 0.75em 0.5em;
   text-align: right;
   margin-top: 1rem !important;
   text-decoration: underline;
 }

 #BorlabsCookieBox ._brlbs-refuse {
   font-size: 16px;
 }

 #BorlabsCookieBox ._brlbs-manage:hover {
   text-decoration: none;
 }

 #BorlabsCookieBox ._brlbs-manage {
   text-decoration: none;
 }

 #BorlabsCookieBox ._brlbs-manage a {
   border-bottom: 1px solid #fff;
   padding-bottom: 3px;
   letter-spacing: 0.042em;
 }

 #BorlabsCookieBox ._brlbs-manage a:hover {
 //  letter-spacing: 0.15em;
 }

 #BorlabsCookieBox ._brlbs-accept {
   text-align: left;
 }

 #BorlabsCookieBox ._brlbs-accept a,
 #BorlabsCookieBox ._brlbs-manage {
   text-align: center;
 }

.cursor {
  cursor: pointer;
}

/*# sourceMappingURL=_barbarabertolini-borlabscookie.css.map */
