/**
 * Barbara Bertolini / Admin Bar 1.0
 *
 * Copyright 2020 Wolf-Dieter Grabner, flowlabs.studio
 * https://www.flowlabs.studio
 *
 */

/* move admin bar to bottom */
* html body { margin-top: 0 !important; }

body.admin-bar {
  margin-top: -34px;
  padding-bottom: 34px;
}

body.wp-admin #footer {
  padding-bottom: 34px;
}

#wpadminbar {
  top: auto !important;
  bottom: 0;
}

#wpadminbar .quicklinks .ab-sub-wrapper {
  bottom: 28px;
}

#wpadminbar .quicklinks .ab-sub-wrapper ul .ab-sub-wrapper {
  bottom: -7px;
}

/*# sourceMappingURL=_barbarabertolini-adminbar.css.map */
