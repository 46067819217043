/**
 * Barbara Bertolini Typographie 1.0
 *
 * Copyright 2020 Wolf-Dieter Grabner, flowlabs.studio
 * https://www.flowlabs.studio
 *
 */
// Typographie

// headers
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  text-align: center;
}

h2,
.h2 {
  margin-bottom: 1.5rem;
}

h3.subtitle {
  font-size: 2.25rem;
  margin-bottom: 42px;
  margin-top: 0;
}

h3,
.h3 {
  margin-bottom: 24px;
  margin-top: 42px;
}

h1,
h2,
.h1,
.h2 {
  font-family: 'Brown-Bold', Georgia, sans-serif;
  font-weight: 600;
}

h4,
.h4 {
  line-height: 1.5rem;
}

h5 {
  margin-bottom: 0;
  font-family: 'Brown-Bold', Georgia, sans-serif;
  font-weight: 600;
}

h5.sub {
  margin-top: 24px;
  margin-bottom: 17px;
}

h5.h3.sub {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

// regular content
/*
.leftalign { text-align: left !important; }
.centered { text-align: center !important; }
*/
hr.separator {
  border-top: 3px solid lighten($bb-gold, 13%);
  width: 4.2em;
  margin-top:  2.625px;
  margin-bottom: 2.25rem;
}

.home hr.separator {
  margin-bottom: 0;
}

.main {
  hr {
    border-top: 3px solid lighten($bb-gold, 13%);
    width: 4.2em;
    margin-top: 2.625rem;
    margin-bottom: 2.25rem;
  }

  ul {
    margin-left: 1.0625rem;
    padding: 0;
  }

  ul.nodots {
    margin-left: 0;
    margin-bottom: 0;

	  li {
      list-style: none;
      padding-left: 0;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0 !important;

      @include media-breakpoint-up('sm') {
        font-size: 1.125rem;
        line-height: 2rem;
        margin-bottom: 0.625rem !important;
      }
	  }
  }

  p,
  li {
    a,
    a:link,
    a:visited {
      text-decoration: underline;
    }

    a:active,
    a:hover {
      text-decoration: none;
    }
  }
}

/*# sourceMappingURL=_barbarabertolini-typographie.css.map */
