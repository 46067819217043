/**
 * Barbara Bertolini Sprecher 1.0
 *
 * Copyright 2020 Wolf-Dieter Grabner, flowlabs.studio
 * https://www.flowlabs.studio
 *
 */

// Sprecher
/*
.superbutton { background: lighten($bb-gold, 7%); border-radius: 0; text-decoration: none !important; font-weight: 600; border: 0; font-size: 20px;
    font-weight: 600; letter-spacing: -0.01em; margin-top: 2em; text-decoration: none !important;
}
.superbutton:hover { background: $bb-gold; text-shadow: 0 0 2px rgba(255,255,255,0.42); }
*/

.single-sprecher {
  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4 {
    text-align: left;
  }

  .list-inline {
    margin-left: 0;

	  a,
    a:link,
    a:visited {
      font-weight: 600;
      text-decoration: none;
    }

	  a:hover,
    a:active {
      text-decoration: underline;
    }

	  li {
      padding-left: 0;
      padding-right: 0;
      color: $bb-gold;
    }
  }

  .portrait {
    margin-bottom: 1.25rem;
  }

  .zitat {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    padding-left: 0.42em;
    text-indent: -0.42em;
    margin-left: -0.42em;
    line-height: 1.5rem;
    color: $bb-gold;
    letter-spacing: -0.024em;
    font-size: 1.25rem;

    @include media-breakpoint-up('sm') {
      font-size: 1.5rem;
      margin-left: -0.842em;
    }
  }

  .main hr.job {
    margin: -0.625rem auto 0.5625rem 0;
    border-top: 1px dotted $bb-gold;
  }

  .position {
    font-size: 0.9375rem;
    line-height: 1.4285;
    font-weight: 600;
    margin-top: 0.625rem;
    margin-bottom: 1.25rem;
    letter-spacing: -0.01em;
  }

  p {
    text-align: justify;
  }

  .backlink {
    margin-bottom: 14px;

    @include media-breakpoint-up('sm') {
      margin-left: -0.842em;
    }
  }
}
/*
#sprecher-intro {
  h2+h3 { margin-top: 0; }
}
#sprecher { margin-bottom: 21px; }
.sprecher { margin-top: 15px; margin-bottom: 15px;
  .item { padding: 5px; }
  .col-borderless {
	 @media (max-width: $screen-xs-max) { margin-left: -15px; margin-right: -15px; }
  }
  .relative { overflow: hidden; color: #fff; }
  .relative:hover .sprecher-info { transform: translate(0px, 0px); }
  .item {
    @media (max-width: $screen-xs-max) {
	  margin-bottom: 10px;
    }
  }
  img { width: 40%;  position: relative; }
  img:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .sprecher-info { background: $bb-gold; left: 40%; position: absolute; padding-top: 40%; top: 0; width: 60%;
	h2, .h2, p { text-align: center !important; }
	.h5 { font-weight: 600; padding-bottom: 3px; }
  }
  @media (max-width: $screen-xs-max) {
	margin-top: 0;
    .h2 { font-size: 17px; line-height: 21px; }
    .h5, p { font-size: 13px; line-height: 13px; }
  }
  @media (min-width: $screen-sm-min) {
    img { width: 100%; }
    .sprecher-info { left: 0; padding-top: 100%; transform: translate(105%, 0px); transition: all 0.3s ease 0s; width: 101%; }
  }
  .copy { left: 0; position: absolute; top: 50%; transform: translateY(-50%); width: 100%; }
  .p1 { padding: 0rem; }
}
*/

/*# sourceMappingURL=_barbarabertolini-sprecher.css.map */
