/**
 * Barbara Bertolini Login 1.1
 *
 * Copyright 2019 Wolf-Dieter Grabner, theflow.cc
 * https://www.theflow.cc
 *
 */
body.login {
  // background: $dr-pongratz-gray-200;
  // background-image: radial-gradient(circle at top center, lighten($dr-pongratz-gray-100, 9%), lighten($dr-pongratz-gray-200, 3%));
  background-repeat: no-repeat;
}

.login #login {
  form {
    padding: 2.375rem 1.5rem 2.5rem;
    background: $bb-white;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.04);
  }

  h1 {
    a {
      // background: url("../images/dr-pongratz.svg") no-repeat center center;
      width: 17rem;
      height: 2rem;
      background-size: contain;
      margin-bottom: 2.5rem;
    }
  }

  #login_error {
    font-size: 0.875rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    a { display: inline-block; }
  }

  label {
    font-size: 0.875rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    // color: $dr-pongratz-gray-900;
    letter-spacing: 0.025rem;
    width: 100%;
  }

  .forgetmenot label {
    font-size: 0.75rem;
    text-transform: none;
    // color: $dr-pongratz-gray-800;
    letter-spacing: 0.02rem;
    font-weight: 300;
    padding-top: 0.4375rem;
    margin-top: 0.25rem;
  }

  .button-primary {
    @extend .btn !optional;

    font-size: 0.875rem;
    line-height: 1.375rem;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    text-shadow: none;
    box-shadow: none;
    border: none;
    height: inherit;
    border-radius: 0;
    // background: $dr-pongratz-green;
    // background: darken($dr-pongratz-sage-green, 9%);
    transition: all 0.1s ease-in-out 0s;
    padding-top: 0.4375rem;
    margin-top: 0.25rem;

    &:hover {
      // background: darken($dr-pongratz-sage-green, 6%);
    }
  }

  #nav,
  #backtoblog {
    font-size: 0.8125rem;
    line-height: 1.375rem;
    // color: $dr-pongratz-gray-800;
    letter-spacing: 0.02rem;

    a,
    a:link,
    a:visited {
      color: inherit;
      text-decoration: none;
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: underline;
    }
  }

  #backtoblog {
    font-size: 0.8125rem;
    // color: $dr-pongratz-gray-800;
    padding-left: 0.5rem;

    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      // color: $dr-pongratz-gray-900;
    }
  }

  .privacy-policy-page-link {
    font-size: 0.8125rem;
    // color: $dr-pongratz-gray-800;
    letter-spacing: 0.02rem;
  }

/*
  .message,
  #login_error {
    @extend .alert;
    @extend .alert-danger;
  }
*/

  .input {
    @extend .form-control !optional;

    border: none;
    border-radius: 0;
    background: darken($bb-white, 3%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03) inset;

    &:focus {
      background: darken($bb-white, 6%);
    }
  }

  #user_pass {
    margin-bottom: 1.25rem;
  }
}

.login-action-lostpassword #login {
  #user_login {
    margin-bottom: 1.25rem;
  }

  .message {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
}
/*# sourceMappingURL=_barbarabertolini-login.css.map */
