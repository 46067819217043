/**
 * Global
 */
// If you're limited in power, invest in illusion.

a {
  color: map-get($theme-colors, 'primary');
}

/**
 * Block editor color palette utilities
 * @see https://git.io/JeyD6
 */

@each $color-name, $color-value in $theme-colors {
  .has-#{$color-name}-color {
    color: $color-value;
  }

  .has-#{$color-name}-background-color {
    background-color: $color-value;
  }
}

// Allgemeines Setup der Seite
html {
  height: 100%;
  background: transparent;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100%;
  height: 100%;
  background: transparent;
}

// virtuell2020
.page-header { margin-top: 1rem; }
.brand { display: none; }
form p { text-align: center; }
