/**
 * Barbara Bertolini Webfonts 1.0
 *
 * Copyright 2020 Wolf-Dieter Grabner, flowlabs.studio
 * https://www.flowlabs.studio
 *
 */
// Webfonts

@font-face {
  font-family: 'Brown-Regular';
  font-style: normal; font-weight: 400;
  src: url('../fonts/Brown-Regular.eot');
  src:
    url('../fonts/Brown-Regular.woff') format('woff'),
    url('../fonts/Brown-Regular.svg#Brown-Regular') format('svg');
}

@font-face {
  font-family: 'Brown-Bold';
  font-style: normal; font-weight: 600;
  src: url('../fonts/Brown-Bold.eot');
  src:
    url('../fonts/Brown-Bold.woff') format('woff'),
    url('../fonts/Brown-Bold.svg#Brown-Bold') format('svg');
}

/*# sourceMappingURL=_barbarabertolini-webfonts.css.map */
