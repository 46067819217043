/**
 * Variables
 */

// Colors
$bb-white:				#fefefe;
$bb-black:				#242424;
$bb-gold:         #86744d;
$bb-gold-light:		#b4a479;
$bb-gold-lighter:	#ddcca9;
$bb-red:				  #b31818;
$bb-green:				#97a256;

$navidi-gold-light:	#f7f4f3;
$navidi-gold:			   #d5cfbd;

$theme-colors: (
  primary: $bb-gold,
);

// Forms
$input-border-radius: 0;
$input-bg: lighten($bb-gold, 42%);

// Fonts
$font-family-brown: 'Brown-Regular', Georgia, sans-serif;
$font-family-base:  $font-family-brown !default;

// v1 Text Colors
$text-color:            $bb-black !default;
$link-color:            $bb-black !default;
$link-hover-color:      darken($bb-black,7%) !default;
$link-hover-decoration: underline !default;
